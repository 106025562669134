import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Container } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Add } from '@material-ui/icons';

import { Subheader, TabPanel } from 'components';
import { useNavigation } from 'hooks';
import FeedbackAttemptList from './attempt/FeedbackAttemptList';
import FeedbackDrillList from './drill/FeedbackDrillList';

const FeedbackList = ({ ...props }) => {
	const navigate = useNavigate();
	const [tabValue, setTabValue] = React.useState(0);
	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const { handleAddClick } = useNavigation({
		history,
		url: '/feedback_drill'
	});

	return (
		<>
			<Subheader
				title="Feedback"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Drill
					</Button>
				]}
			/>
			<Container maxWidth="lg">
				<>
					<AppBar
						position="static"
						style={{ background: '#FFF', color: '#000' }}
					></AppBar>
					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						aria-label="Feedback Tabs"
					>
						<Tab label="In Progress" />
						<Tab label="Available" />
						<Tab label="Viewed" />
						<Tab label="Drills" />
					</Tabs>
					<TabPanel value={tabValue} index={0}>
						<FeedbackAttemptList title={'In Progress'} status={'in_progress'} />
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<FeedbackAttemptList title={'Available'} status={'available'} />
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						<FeedbackAttemptList title={'Viewed'} status={'viewed'} />
					</TabPanel>
					<TabPanel value={tabValue} index={3}>
						<FeedbackDrillList />
					</TabPanel>
				</>
			</Container>
		</>
	);
};

export default FeedbackList;
