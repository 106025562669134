import {
	Chip,
	TextField,
	Typography,
	alpha,
	makeStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useMemo, useState } from 'react';

import { extractSkills } from 'lib/utils';

const MultiAutosuggest = ({
	className,
	value,
	options,
	label,
	name,
	placeholder = 'Select',
	handleChange,
	handleInputChange,
	...props
}) => {
	const classes = useStyles();
	const [defaultValue, setDefaultValue] = useState();
	const showPlaceHolder = useMemo(() => value.length === 0, [value]);

	const handleOnChange = (_, newValue) => {
		handleChange({
			target: {
				name: name,
				value: newValue.map((v) => v.value)
			}
		});
	};

	const setInitialValues = () => {
		let initialValues = [];

		// skills categories from feedback drills are shaped differently
		const extractedSkills = extractSkills(value);

		initialValues = options.filter(
			(o) =>
				value.includes(o.value) ||
				extractedSkills.includes(o.label.toLowerCase())
		);
		setDefaultValue(initialValues);
	};

	useEffect(() => {
		if (value) {
			setInitialValues();
		}
	}, [value]);

	return (
		<div className={classes.root}>
			<Typography
				variant="body2"
				color="textSecondary"
				className={classes.label}
			>
				{label}
			</Typography>
			{defaultValue && (
				<Autocomplete
					multiple
					classes={{ inputRoot: classes.inputRoot }}
					value={defaultValue}
					onChange={handleOnChange}
					options={options}
					getOptionLabel={(option) => option.label}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip label={option.label} {...getTagProps({ index })} />
						))
					}
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								variant="outlined"
								placeholder={showPlaceHolder ? placeholder : ''}
								className={classes.inputBase}
							/>
						);
					}}
				/>
			)}
		</div>
	);
};

export default MultiAutosuggest;

const useStyles = makeStyles((theme) => ({
	root: {},
	label: {
		marginBottom: 0,
		borderColor: theme.palette.placeHolder.text
	},
	inputRoot: {
		backgroundColor: 'white',
		padding: '0 !important'
	},
	inputBase: {
		padding: 0,
		width: '100%',
		'& input': {
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			height: 22.5,
			appearance: 'none',
			padding: theme.spacing(1.5, 1),
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			fontSize: 15,
			'&:focus': {
				boxShadow: `${alpha('#999999', 0.25)} 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.light
			}
		}
	}
}));
