import {
	Button,
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React from 'react';

import { Image, Label } from 'components';
import { useMenu } from 'hooks';
import { ellipsis, properCase } from 'lib/utils';

const FeedbackDrillItem = ({
	feedbackDrill,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(feedbackDrill, e)}>
				Edit
			</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(feedbackDrill, e)}>
				Delete
			</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(feedbackDrill.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(feedbackDrill)}
						name="feedback_drill"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(feedbackDrill)}>
					<Image src={feedbackDrill.image_url} variant="rounded" size={80} />
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(feedbackDrill)}>
					{feedbackDrill.title}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(feedbackDrill)}>
					{ellipsis(feedbackDrill.description, 50)}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(feedbackDrill)}>
					{feedbackDrill.skill_categories.map((v, i) => (
						<Label
							label={properCase(v[Object.keys(v)[0]].skill || '')}
							key={i}
						/>
					))}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(feedbackDrill)}>
					{properCase(feedbackDrill.status)}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default FeedbackDrillItem;
