import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SubheaderV2 } from 'components';
import LessonForm from 'features/courses/lessons/LessonForm';
import {
	useAlerts,
	useCourses,
	useLessons,
	useQuery,
	useUploadFileInMemory
} from 'hooks';
import { validateLesson } from 'validations/lessons';

const LessonNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const {
		query: { course_id, position }
	} = useQuery({ handleSearch: () => {} });
	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();
	const { showAlertSuccess, showAlertError } = useAlerts();
	const { course, findCourse } = useCourses({ id: course_id });
	const { lesson, handleChange, createLesson, findLesson, setLesson } =
		useLessons({
			id: id
		});

	const handleSaveClick = async () => {
		const valid = validateLesson(lesson);
		if (valid.isValid) {
			delete lesson.difficulty;
			await createLesson(lesson, memoryFile);
			showAlertSuccess('Lesson has been created');
			navigate(`/courses/${course_id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleUpload = async (file, type) => {
		await handleUploadFile(file, type);
	};

	const handleDelete = async () => {
		await removeFile();
	};

	useEffect(() => {
		if (id) findLesson(id);
		if (course_id) findCourse(course_id);
		setLesson({
			...lesson,
			course_id: parseInt(course_id),
			position: parseInt(position)
		});
	}, []);

	return (
		<>
			<SubheaderV2
				title="Add a new lesson"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save Lesson
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<LessonForm
						lesson={lesson}
						courseTitle={course?.title}
						memoryFile={memoryFile}
						handleUpload={handleUpload}
						handleDelete={handleDelete}
						handleChange={handleChange}
					/>
				</Box>
			</Container>
		</>
	);
};

export default LessonNew;
