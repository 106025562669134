import moment from 'moment';
import { compose, replace, map, split, join, capitalize } from 'lodash/fp';

export const buildOptions = (items, key, value) => {
	if (!items) return null;
	let options = [];
	let list = [];
	if (items.data) {
		list = items.data;
	} else {
		list = items;
	}
	list.forEach((item, idx) => {
		let label = item[value];
		if (Array.isArray(value)) {
			label = value.map((v) => eval(`item.${v}`)).join(' - ');
		}
		return options.push({
			value: item[key],
			label: label
		});
	});
	return options;
};

export const syntheticEvent = (value, name, type = 'text') => {
	let ev = {
		target: {
			value,
			name,
			type
		}
	};
	return ev;
};

export const objectMap = (obj, fn) =>
	Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const properCase = (text, all = false) => {
	if (text == null || text == 'undefined')
		throw new Error('Please provide text input');

	return all === false
		? text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase()
		: text
				.split(' ')
				.map(
					(item) => item.slice(0, 1).toUpperCase() + item.slice(1).toLowerCase()
				)
				.join(' ');
};

export const ellipsis = (text, maxLength) => {
	if (
		text == null ||
		text == 'undefined' ||
		maxLength == null ||
		maxLength == 'undefined'
	)
		throw new Error('Please provide text and maxLength inputs');

	return text.length <= maxLength
		? text
		: text.slice(0, maxLength).trim() + '...';
};

export const removeUnderscoresAndCapitalize = (text) =>
	compose(
		join(' '),
		map((v) => capitalize(v)),
		split(' '),
		replace('_', ' ')
	)(text);

export const extractSkills = (inputArray) => {
	return inputArray.map((obj) => obj.skill);
};

export const formatDisplayDate = (date) => {
	return moment(date).format('D MMM YYYY');
};

export const promoRuleOptions = (rules) =>
	rules.map((rule) => ({ value: rule.id, label: rule.name }));

export const convertVideoToImage = (url) =>
	(url || '').replace(/(.mp4|.mov)/i, '.jpg');
