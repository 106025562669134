import React, { useState } from 'react';
import {
	Grid,
	Typography,
	TextField,
	Button,
	Divider
} from '@material-ui/core';
import { VideocamOff } from '@material-ui/icons';
import { useAlerts } from 'hooks';

const Submissions = ({
	videos,
	handleChange,
	editFeedback,
	event,
	addEventSubmissionFeedback,
	reloadEvent
}) => {
	const { showAlertSuccess, showAlertError } = useAlerts();
	const [editStates, setEditStates] = useState([]);

	const videosArray = videos || [];

	const handleEditClick = (index) => {
		const updatedEditStates = [...editStates];
		updatedEditStates[index] = true;
		setEditStates(updatedEditStates);
	};

	const getButtonText = (video, index) => {
		if (!editFeedback && !editStates[index] && video.feedback) {
			return 'Update Feedback';
		} else {
			return 'Save Feedback';
		}
	};

	const handleFeedback = async (submissionId, index) => {
		const payload = {
			submissionId: submissionId,
			params: {
				feedback: {
					comment: event.comment
				}
			}
		};

		await addEventSubmissionFeedback(payload)
			.then(() => {
				showAlertSuccess('Feedback saved!');
				reloadEvent();

				const updatedEditStates = [...editStates];
				updatedEditStates[index] = false;
				setEditStates(updatedEditStates);
			})
			.catch((err) => {
				showAlertError(err.message);
			});
	};

	const handleVideoTitle = (videoId) => {
		const video =
			event && event.event_videos?.find((video) => video.id === videoId);
		return video.title || 'Video Title Not Found';
	};

	return (
		<>
			{videosArray.length > 0 ? (
				videosArray.map((video, index) => (
					<Grid key={index} xs={12} item container spacing={1}>
						<Grid container spacing={2}>
							{/* 1st Row - Title */}
							<Grid item xs={12}>
								<Typography variant="h5" gutterBottom>
									{handleVideoTitle(video.event_video_id)}
								</Typography>
								<Divider />
							</Grid>

							{/* 2nd Row - Two Columns */}
							<Grid item xs={6}>
								<Typography variant="subtitle1">Benchmark Video</Typography>
								<video width="100%" controls>
									<source src={video.event_video_url} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle1">User's Attempt</Typography>
								<video width="100%" controls>
									<source src={video.video_url} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</Grid>

							{/* 3rd Row - Text Area Input */}
							{!editFeedback && !editStates[index] && video.feedback ? (
								<>
									<Grid item xs={12}>
										<Typography variant="body1" color="textPrimary">
											{video.feedback.comment}
										</Typography>
										<div style={{ paddingTop: '20px' }}>
											<Button
												variant="contained"
												color="primary"
												onClick={() => handleEditClick(index)}
											>
												Edit Feedback
											</Button>
										</div>
									</Grid>
								</>
							) : (
								<>
									<Grid item xs={12}>
										<TextField
											name={`comment`}
											variant="outlined"
											label="Your Feedback"
											multiline
											minRows={4}
											fullWidth
											defaultValue={video.feedback && video.feedback.comment}
											onChange={handleChange}
										/>
										<div style={{ paddingTop: '20px' }}>
											<Button
												variant="contained"
												color="primary"
												onClick={() => handleFeedback(video.id, index)}
											>
												{getButtonText(video, index)}
											</Button>
										</div>
									</Grid>
								</>
							)}
						</Grid>
					</Grid>
				))
			) : (
				<Grid container direction="column" alignItems="center" spacing={1}>
					<Grid item>
						<VideocamOff fontSize="large" color="disabled" />
					</Grid>
					<Grid item>
						<Typography variant="h6" color="textSecondary">
							This user has not submitted any videos yet.
						</Typography>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default Submissions;
