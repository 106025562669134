import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Placeholder, SortableTableHeader } from 'components';
import SearchForm from 'features/feedback/SearchForm';
import {
	useAlerts,
	useFeedbackDrills,
	useNavigation,
	useSelected
} from 'hooks';
import FeedbackDrillItem from './FeedbackDrillItem';

const TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image',
		sortable: false,
		hidden: false
	},

	{
		value: 'title',
		label: 'Title',
		sortable: true,
		hidden: false
	},

	{
		value: 'description',
		label: 'Description',
		sortable: true,
		hidden: false
	},

	{
		value: 'skill_categories',
		label: 'Skills',
		sortable: false,
		hidden: false
	},

	{
		value: 'status',
		label: 'Status',
		sortable: true,
		hidden: false
	}
];

const FeedbackDrillList = ({ ...props }) => {
	const navigate = useNavigate();

	const {
		isLoading,
		isLoaded,
		isEmpty,
		feedbackDrills,
		findFeedbackDrills,
		deleteFeedbackDrill,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadFeedbackDrills,
		sortKey,
		sortDirection,
		handleSort
	} = useFeedbackDrills({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/feedback_drill'
	});

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected
	} = useSelected();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleDeleteClick = async (feedbackDrill) => {
		await deleteFeedbackDrill(feedbackDrill);
		showAlertSuccess('FeedbackDrill has been deleted');
		reloadFeedbackDrills();
	};

	React.useEffect(() => {
		findFeedbackDrills(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	return (
		<>
			<Paper>
				<SearchForm
					isLoading={isLoading}
					query={query}
					handleSearch={findFeedbackDrills}
				/>
				{!isEmpty ? (
					<TableContainer component={Paper} elevation={0}>
						<Table>
							<TableHead>
								<TableRow>
									{TABLE_HEADERS.filter((h) => !h.hidden).map((header, idx) => (
										<SortableTableHeader
											key={idx}
											handleSort={handleSort}
											value={`feedback_drills.${header.value}`}
											sortable={header.sortable}
											sortKey={sortKey}
											sortDirection={sortDirection}
										>
											{header.label}
										</SortableTableHeader>
									))}
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{feedbackDrills.map((feedbackDrill, idx) => (
									<FeedbackDrillItem
										key={idx}
										feedbackDrill={feedbackDrill}
										isSelectable={false}
										selectedIds={selectedIds}
										handleSelect={handleSelect}
										handleClick={handleClick}
										handleDeleteClick={handleDeleteClick}
										handleEditClick={handleEditClick}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Placeholder
						icon={<Search />}
						title="No feedbackDrills"
						subtitle="Please try another search"
					/>
				)}
			</Paper>
		</>
	);
};

export default FeedbackDrillList;
