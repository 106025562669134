import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Placeholder, SortableTableHeader } from 'components';
import SearchForm from 'features/feedback/SearchForm';
import {
	useAlerts,
	useFeedbackAttempts,
	useNavigation,
	useSelected
} from 'hooks';
import FeedbackAttemptItem from './FeedbackAttemptItem';

const TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image',
		sortable: false,
		hidden: false
	},

	{
		value: 'title',
		label: 'Title',
		sortable: false,
		hidden: false
	},

	{
		value: 'description',
		label: 'Description',
		sortable: false,
		hidden: false
	},

	{
		value: 'user',
		label: 'User',
		sortable: false,
		hidden: false
	},

	{
		value: 'skill_categories',
		label: 'Skills',
		sortable: false,
		hidden: false
	},

	{
		value: 'created_at',
		label: 'Created On',
		sortable: true,
		hidden: false
	}
];

const FeedbackAttemptList = ({ title, status = null }) => {
	const navigate = useNavigate();

	const {
		isLoading,
		isEmpty,
		feedbackAttempts,
		findFeedbackAttempts,
		deleteFeedbackAttempt,
		paginate,
		query,
		page,
		numPages,
		reloadFeedbackAttempts,
		sortKey,
		sortDirection,
		handleSort
	} = useFeedbackAttempts({});

	const searchParams = React.useMemo(
		() => ({
			'feedback_attempts.status': status
		}),
		[status]
	);

	const { handleClick, handleEditClick } = useNavigation({
		navigate,
		url: '/feedback_attempt'
	});

	const { selectedIds, handleSelect } = useSelected();

	const { showAlertSuccess } = useAlerts();

	const handleDeleteClick = async (feedbackAttempt) => {
		await deleteFeedbackAttempt(feedbackAttempt);
		showAlertSuccess('FeedbackAttempt has been deleted');
		reloadFeedbackAttempts();
	};

	React.useEffect(() => {
		findFeedbackAttempts(
			{
				...searchParams,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [searchParams, page, sortKey, sortDirection]);

	return (
		<>
			<Paper>
				<SearchForm
					isLoading={isLoading}
					query={query}
					handleSearch={findFeedbackAttempts}
				/>
				{!isEmpty ? (
					<TableContainer component={Paper} elevation={0}>
						<Table>
							<TableHead>
								<TableRow>
									{TABLE_HEADERS.filter((h) => !h.hidden).map((header, idx) => (
										<SortableTableHeader
											key={idx}
											handleSort={handleSort}
											value={`feedback_attempts.${header.value}`}
											sortable={header.sortable}
											sortKey={sortKey}
											sortDirection={sortDirection}
										>
											{header.label}
										</SortableTableHeader>
									))}
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{feedbackAttempts.map((feedbackAttempt, idx) => (
									<FeedbackAttemptItem
										key={idx}
										feedbackAttempt={feedbackAttempt}
										isSelectable={false}
										selectedIds={selectedIds}
										handleSelect={handleSelect}
										handleClick={handleClick}
										handleDeleteClick={handleDeleteClick}
										handleEditClick={handleEditClick}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Placeholder
						icon={<Search />}
						title="No feedbackAttempts"
						subtitle="Please try another search"
					/>
				)}
			</Paper>
			{!isEmpty && (
				<Box my={1}>
					<Pagination
						onChange={(ev, page) => paginate(page)}
						color="primary"
						size="small"
						shape="rounded"
						page={page}
						count={numPages}
					/>
				</Box>
			)}
		</>
	);
};

export default FeedbackAttemptList;
