import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import clsx from 'clsx';
import { clone } from 'lodash';

import {
	Box,
	Button,
	Container,
	Grid,
	Paper,
	makeStyles
} from '@material-ui/core';
import { ArrowRight, Check } from '@material-ui/icons';
import { Label, LineItem, Subheader, TabPanel } from 'components';
import { useAlerts, useFeedbackAttempts } from 'hooks';
import {
	removeUnderscoresAndCapitalize,
	ellipsis,
	objectMap,
	properCase
} from 'lib/utils';
import FeedbackAttemptForm from './FeedbackAttemptForm';

const FeedbackAttemptEdit = ({ ...props }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { id } = useParams();
	const [tabValue, setTabValue] = React.useState(0);

	const {
		feedbackAttempt,
		previousFeedbackAttempts,
		handleChange,
		updateFeedbackAttempt,
		findFeedbackAttempt,
		findPreviousFeedbackAttempts,
		setFeedbackAttempt
	} = useFeedbackAttempts({ id: id });
	const { showAlertSuccess } = useAlerts();

	const skillCategories = (obj) =>
		objectMap(obj, (item) => (
			<Label
				label={properCase(item.skill + ' - ' + item.value)}
				key={item.skill}
			/>
		));

	useEffect(() => {
		if (id) findFeedbackAttempt(id);
	}, []);

	useEffect(() => {
		const previousFeedbackAttempts = async () => {
			let resp = await findPreviousFeedbackAttempts(feedbackAttempt.id);
			console.log(resp);
		};
		if (feedbackAttempt?.id) previousFeedbackAttempts();
	}, [feedbackAttempt?.id]);

	const handleSkillScoreChange = (ev) => {
		const { name, value } = ev.target;
		const cloned_skill_scores = clone(feedbackAttempt.skill_scores);

		cloned_skill_scores[name] = {
			...cloned_skill_scores[name],
			value: parseInt(value)
		};

		setFeedbackAttempt({
			...feedbackAttempt,
			skill_scores: cloned_skill_scores
		});
	};

	const handleSaveClick = async () => {
		await updateFeedbackAttempt(feedbackAttempt);
		showAlertSuccess('Feedback Attempt has been updated');
		navigate(`/feedback_attempt/${feedbackAttempt.id}`);
	};

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<React.Fragment>
			<Subheader
				title="Edit Feedback Attempt"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					aria-label="Feedback Tabs"
				>
					<Tab label="Current Attempt" />
					{previousFeedbackAttempts.length > 0 && (
						<Tab label="Previous Attempts" />
					)}
				</Tabs>
				<TabPanel value={tabValue} index={0}>
					<Paper>
						<Box p={4}>
							<FeedbackAttemptForm
								feedbackAttempt={feedbackAttempt}
								handleChange={handleChange}
								handleSkillScoreChange={handleSkillScoreChange}
							/>
							<Box py={2}>
								<Button
									color="primary"
									variant="contained"
									startIcon={<Check />}
									onClick={handleSaveClick}
								>
									Save
								</Button>
							</Box>
						</Box>
					</Paper>
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<Paper>
						<Box padding={4}>
							{previousFeedbackAttempts
								.filter((v) => v.id !== parseInt(id))
								.reverse()
								.map((item) => (
									<div key={item.id} className={clsx(classes.attemptContainer)}>
										<Grid container spacing={1}>
											<Grid item xs={6} sm={6}>
												{item?.video && (
													<video controls src={item?.video.url} width="100%" />
												)}
											</Grid>
											<Grid item xs={6} sm={6}>
												<Grid item xs={12} sm={12}>
													<LineItem
														label="Attempt No:"
														value={item.attempt_no}
													/>
													<LineItem
														label="Status:"
														value={removeUnderscoresAndCapitalize(item.status)}
													/>
													<LineItem
														label="Feedback:"
														value={ellipsis(item.feedback || '', 300)}
													/>
													<LineItem
														label={'Skill Scores: '}
														value={Object.keys(
															skillCategories(item.skill_scores)
														).map((v) => skillCategories(item.skill_scores)[v])}
													/>
													<LineItem
														label="Updated On:"
														value={item.updated_at}
													/>
													<LineItem
														label="Created On:"
														value={item.created_at}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Button
											className={clsx(classes.button)}
											color="primary"
											variant="contained"
											startIcon={<ArrowRight />}
											onClick={() => navigate(`/feedback_attempt/${item.id}`)}
										>
											View
										</Button>
										<hr className={clsx(classes.hr)} />
									</div>
								))}
						</Box>
					</Paper>
				</TabPanel>
			</Container>
		</React.Fragment>
	);
};

export default FeedbackAttemptEdit;

const useStyles = makeStyles((theme) => ({
	attemptContainer: {
		position: 'relative'
	},
	hr: {
		margin: 20
	},
	button: {
		borderColor: 'black',
		borderWidth: 1,
		position: 'absolute',
		top: 10,
		right: 5
	}
}));
