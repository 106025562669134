import {
	Casino,
	EmojiEvents,
	Feedback,
	LocalOffer,
	PeopleOutlined,
	Person,
	Redeem,
	School,
	Tv,
	Settings,
	Videocam,
	CalendarToday
} from '@material-ui/icons';

export const MENU_ITEMS = {
	loggedIn: [
		{ value: '/users', text: 'Users', icon: Person },
		{
			value: '/benefits',
			text: 'Benefits',
			icon: LocalOffer
		},
		{
			value: '/courses',
			text: 'Courses',
			icon: School
		},
		{
			value: '/editorials',
			text: 'Editorials',
			icon: Videocam
		},
		{ value: '/feedback', text: 'Feedback', icon: Feedback },
		{
			value: '/events-partners',
			text: 'Events & Partners',
			icon: CalendarToday
		},
		{
			value: '/lotteries',
			text: 'Lotteries',
			icon: Casino
		},
		{
			value: '/promo_codes',
			text: 'Promo Codes',
			icon: Redeem
		},
		{
			value: '/rewards',
			text: 'Rewards',
			icon: EmojiEvents
		},
		{
			value: '/trainers',
			text: 'Trainers',
			icon: PeopleOutlined
		},
		{ value: '/topics', text: 'Topics', icon: Tv },
		{
			value: '/videos',
			text: 'Videos',
			icon: Videocam
		},
		{
			value: '/settings',
			text: 'Settings',
			icon: Settings
		}
	],
	loggedOut: [
		{ value: '/login', text: 'Sign In' },
		{
			value: '/forgot_password',
			text: 'Reset password'
		}
	]
};

export const NO_AUTH_ROUTES = [
	'/',
	'/login',
	'/forgot_password',
	'/verify_pin',
	'/reset_password'
];
