import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	Box,
	Button,
	Container,
	Grid,
	Paper,
	Typography
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { LineItem, Subheader, TextInput } from 'components';
import { useFeedbackAttempts } from 'hooks';
import {
	removeUnderscoresAndCapitalize,
	objectMap,
	properCase
} from 'lib/utils';

const FeedbackAttemptShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { feedbackAttempt, findFeedbackAttempt } = useFeedbackAttempts({
		id: id
	});

	const skillScores = useMemo(
		() =>
			objectMap(feedbackAttempt.skill_scores || {}, (item, key, i) => ({
				value: item.value || 0,
				label: properCase(item.skill),
				name: key,
				key: key,
				placeholder: item.skill.toUpperCase(),
				handleChange: () => {},
				type: 'number'
			})),
		[feedbackAttempt.skill_scores]
	);

	useEffect(() => {
		if (id) findFeedbackAttempt(id);
	}, []);

	const handleEditClick = () => navigate(`/feedback_attempt/${id}/edit`);

	return (
		<React.Fragment>
			<Subheader
				title={'Feedback Attempt ' + (feedbackAttempt.attempt_no || '')}
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<ArrowRight />}
						onClick={handleEditClick}
					>
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<React.Fragment>
							<Grid container spacing={1}>
								<Grid item xs={6} sm={6}>
									{feedbackAttempt?.video && (
										<video
											controls
											src={feedbackAttempt?.video.url}
											width="100%"
										/>
									)}
								</Grid>
								<Grid item xs={6} sm={6}>
									<Grid item xs={12} sm={12}>
										<LineItem
											label="Feedback Drill:"
											value={feedbackAttempt.feedback_drill?.title}
										/>

										<LineItem
											label="Attempt No:"
											value={feedbackAttempt.attempt_no}
										/>
										<LineItem
											label="Status:"
											value={removeUnderscoresAndCapitalize(
												feedbackAttempt.status || ''
											)}
										/>
										<hr />
										<LineItem
											label="Updated On:"
											value={feedbackAttempt.updated_at || ''}
										/>
										<LineItem
											label="Created On:"
											value={feedbackAttempt.created_at || ''}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={12}>
									<Box paddingTop={4}>
										<Typography variant="h5">Feedback</Typography>
										<TextInput
											value={feedbackAttempt.feedback || ''}
											label=""
											name="feedback"
											placeholder="Feedback"
											handleChange={() => {}}
											type={'text'}
											multiline={true}
											rows={10}
											disabled={true}
										/>
									</Box>
									<Box sx={{ marginTop: 20 }}>
										<Typography variant="h5">Skill Scores</Typography>
										{Object.keys(skillScores).map((key) => (
											<TextInput
												key={skillScores[key].key}
												value={skillScores[key].value}
												label={skillScores[key].label}
												name={key}
												placeholder={skillScores[key].placeholder || ''}
												handleChange={skillScores[key].handleChange}
												type={skillScores[key].type}
												disabled={true}
											/>
										))}
									</Box>
								</Grid>
							</Grid>
						</React.Fragment>
					</Box>
				</Paper>
			</Container>
		</React.Fragment>
	);
};

export default FeedbackAttemptShow;
