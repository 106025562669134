import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const DisplayImage = ({ label, url, width = '100%', className }) => {
	const classes = useStyles();

	return (
		<div className={clsx(className?.root, classes.root)}>
			<Typography
				variant="body1"
				color="textSecondary"
				className={clsx(className?.label, classes.label)}
				gutterBottom
			>
				{label}
			</Typography>
			<img src={url} width={width} />
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: 32
	},
	label: {},
	text: {}
}));

export default DisplayImage;
