import { List, ListItem, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { Label, LineItem, Maybe } from 'components';

const LessonDetails = ({ lesson }) => {
	const classes = useStyles();

	return (
		<List>
			<Typography variant="h5">Basic Information</Typography>
			<LineItem label="Lesson Name" value={lesson.title} />
			<LineItem label="Lesson number" value={lesson.position} />
			<LineItem label="Course" value={lesson.course?.title} />
			<LineItem
				label="Premium"
				value={<Label label={lesson.premium ? 'Yes' : 'No'} />}
			/>
			<Maybe display={!!lesson?.experience_points}>
				<LineItem label={'XP'} value={lesson?.experience_points} />
			</Maybe>
			<Typography variant="h5">Video Modifiers</Typography>
			<LineItem label="Main:" value={lesson?.video_modifier_main} />
			<LineItem label="Clip:" value={lesson?.video_modifier_clip} />
			<LineItem label="Thumbnail:" value={lesson?.video_modifier_thumbnail} />
			<Typography variant="h5">Videos</Typography>
			{lesson.video_url && (
				<>
					<Typography
						component="span"
						variant="body2"
						className={classes.videoTitle}
					>
						{'Lesson Video'}
					</Typography>
					<ListItem>
						<video
							src={lesson.video_url}
							controls
							muted
							height="240"
							width="auto"
						/>
					</ListItem>
				</>
			)}
			{lesson.video2_url && (
				<>
					<Typography
						component="span"
						variant="body2"
						className={classes.videoTitle}
					>
						{'Lesson Preview Video'}
					</Typography>
					<ListItem>
						<video
							src={lesson.video2_url}
							controls
							muted
							height="240"
							width="auto"
						/>
					</ListItem>
				</>
			)}
		</List>
	);
};

export default LessonDetails;

const useStyles = makeStyles((theme) => ({
	captions: {
		whiteSpace: 'pre-wrap',
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(2),
		borderRadius: theme.shape.borderRadius
	},
	videoTitle: {
		marginLeft: 15
	}
}));
