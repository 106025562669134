import React, { useMemo } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { LineItem, TextInput } from 'components';
import { objectMap } from 'lib/utils';

const FeedbackAttemptForm = ({
	feedbackAttempt,
	handleChange,
	handleSkillScoreChange
}) => {
	const skillScores = useMemo(
		() =>
			objectMap(feedbackAttempt?.skill_scores || {}, (item, key, i) => ({
				value: item.value || 0,
				label: item.skill.slice(0, 1).toUpperCase() + item.skill.slice(1),
				name: key,
				key: key,
				placeholder: item.skill.slice(0, 1).toUpperCase() + item.skill.slice(1),
				handleChange: handleSkillScoreChange,
				type: 'number'
			})),
		[feedbackAttempt.skill_scores]
	);

	return (
		<React.Fragment>
			<Grid container spacing={1}>
				<Grid item xs={6} sm={6}>
					{feedbackAttempt?.video && (
						<video controls src={feedbackAttempt?.video.url} width="100%" />
					)}
				</Grid>
				<Grid item xs={6} sm={6}>
					<Grid item xs={12} sm={12}>
						<LineItem
							label="Feedback Drill:"
							value={feedbackAttempt.feedback_drill?.title}
						/>
						<LineItem label="Attempt No:" value={feedbackAttempt.attempt_no} />
						<LineItem label="Created On:" value={feedbackAttempt.created_at} />
						<LineItem label="Updated On:" value={feedbackAttempt.updated_at} />
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Box sx={{ marginTop: 20 }}>
						<Typography variant="h5">Feedback</Typography>
						<TextInput
							value={feedbackAttempt.feedback || ''}
							label=""
							name="feedback"
							placeholder="Feedback"
							handleChange={handleChange}
							type={'text'}
							multiline={true}
							rows={10}
						/>
					</Box>
					<Box sx={{ marginTop: 20 }}>
						<Typography variant="h5">Skill Scores</Typography>
						{Object.keys(skillScores).map((key) => (
							<TextInput
								key={skillScores[key].key}
								value={skillScores[key].value}
								label={skillScores[key].label}
								name={key}
								placeholder={skillScores[key].placeholder || ''}
								handleChange={handleSkillScoreChange}
								type={skillScores[key].type}
							/>
						))}
					</Box>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default FeedbackAttemptForm;
